<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Order Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Product Name:" label-for="product_name">
                                    <b-form-input id="product_name" placeholder="Enter product name" autocomplete="off"
                                        v-model="product_name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-1" label="User Name:" label-for="user_name">
                                <b-form-input id="user_name" placeholder="Enter user name" autocomplete="off"
                                    v-model="user_name" required>
                                </b-form-input>
                            </b-form-group>
                        </div>
    
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="address">
                                    <b-form-input id="address" type="text" placeholder="Enter street address"
                                        v-model="address" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Quantity:" label-for="quantity">
                                    <b-form-input id="quantity" type="quantity" placeholder="Enter quantity"
                                        v-model="quantity" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Qunatity Amount:" label-for="amount">
                                    <b-form-input id="amount" placeholder="Enter quantity amount "
                                        v-model="amount" required></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Status:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status" v-model="status" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                   
                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,

            product_name: "",
            user_name:"",
            address:"",
            quantity: "",
            amount: "",
            status: '',
            successMessage: "",
        
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

    created() {
        // Load the clients data when the component is created
       
        const userId = this.$route.params.id;
        axios
            .get(`OrderShow/${userId}`)
            .then((response) => {
                const data = response.data.data;
                this.product_name = data.product.name; 
                this.user_name = data.user.first_name; 
                this.address = data.address;
                this.status = data.status;
                this.quantity = data.quantity;
                this.amount = data.amount;         

            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
   

        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file

            const formData = new FormData();
            formData.append("product_name", this.product_name);
            formData.append("user_name", this.user_name);
            formData.append("address", this.address);
            formData.append("status", this.status);
            formData.append("quantity", this.quantity);
            formData.append("amount", this.amount);
            axios
                .post(`OrderUpdate/${this.editedUser.id}`, formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Order Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", // Background color
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListPolice' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(error.response.data);
                    this.isLoading = false;
                });
        },

        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.image = file;
            }
        },

     
      
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
};
</script>
